import { Component, Inject, OnInit } from '@angular/core';
import { GLOBAL_RX_STATE, GlobalState } from '../../services/state';
import { Page, PageSettingsService, PageType } from './service';
import { FormGroup } from '@angular/forms';
import { DataReadService } from '../../services/data-read';
import { LOCATION } from '@ng-web-apis/common';
import { RxState } from '@rx-angular/state';
import { FormService } from '../../services/form';
import { RxEffects } from '@rx-angular/state/effects';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ava-page-settings-form',
  templateUrl: './form.html',
})
export class PageSettingsFormComponent implements OnInit {
  globalState$ = this.globalState.select();
  formGroup: FormGroup | undefined;
  pageTypeHeader: string = '';
  pages: Page[] = [];

  constructor(
    @Inject(LOCATION)
    private location: Location,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects,
    private dataReadService: DataReadService,
    private formService: FormService,
    private pageSettingsService: PageSettingsService,
  ) {
    this.pages = pageSettingsService.pages;
  }

  get headerPageType$(): Observable<any> {
    const pageTypeForm = this.formGroup?.get('pageType');
    if (pageTypeForm) {
      return pageTypeForm.valueChanges.pipe(
        tap((pageType: PageType) => {
          let pageTypeHeader = 'Type';
          const pageTypeLabel = this.pages.find((page) => page.type === pageType)?.label;
          if (pageType && pageTypeLabel) {
            pageTypeHeader += ': ' + pageTypeLabel;
          }
          this.pageTypeHeader = pageTypeHeader;
        }),
      );
    }
    return of(null);
  }

  ngOnInit() {
    const pageBlockId = this.globalState.get('pageBlock', 'id');
    if (pageBlockId) {
      this.formGroup = this.formService.forms[pageBlockId];
      this.rxEffects.register(this.headerPageType$);
      this.formGroup?.enable();
    }
  }
}
