import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { combineLatestWith, debounceTime, Observable, of, takeWhile, tap } from 'rxjs';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { JoinRenewService, OpStatus } from '../../join-renew.service';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../services/state';
import {
  Flow,
  HOOSIER_RX_STATE,
  HoosierService,
  HoosierState,
  JoinSection,
  Section,
  SessionDocResponse,
  SessionDocResponseObject,
} from '../hoosier.service';
import { AlertGroup, AlertMessage, AlertMessageService, AlertType } from '../../../../services/alert-message';
import Timestamp = firebase.firestore.Timestamp;
import { EventName, SaveCacheSettings } from '@aaa/interface-joinRenew-joinRenewLib';
import {
  ConnectSuitePrices,
  MembershipConnectSuiteMethod,
  MembershipConnectSuitePricePreviewsEventPayload,
  MembershipConnectSuitePricePreviewsResponse,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';

export interface PricePreviewsResponseObject extends SessionDocResponseObject {
  error: any;
  // meta: SessionDocMeta
  response: PricePreviewsResponse;
}

export interface PricePreviewsResponse extends MembershipConnectSuitePricePreviewsResponse {
  promoNoEnrollPrices: ConnectSuitePrices[];
  noPromoNoEnrollPrices: ConnectSuitePrices[];
  enrollFee: number;
}

@Injectable({
  providedIn: 'root',
})
export class PricePreviewsService {
  form: FormGroup;
  numberOfRetries: number = 0;

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private joinRenewService: JoinRenewService,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
    private alertMessageService: AlertMessageService,
  ) {
    this.form = hoosierService.form;
    rxEffects.register(this.couponCode$);
    rxEffects.register(this.pricePreviews$);
    // this.rxEffects.register(this.pricePreviews_account$)
    rxEffects.register(this.promoCode$);
    this.form.enable();
    // rxEffects.register(this.form$)
    hoosierState.set('pricePreviewsFunction', () => this.pricePreviewsFunction);

    rxEffects.register(this.PRICE_PREVIEWS_KEY$);
    rxEffects.register(this.retryOnError$);
  }

  get promoCode$(): Observable<any> {
    const promoCodeForm = this.form.get(['membershipPayload', 'membership', 'promoCode']);
    if (promoCodeForm) {
      return promoCodeForm.valueChanges.pipe(
        combineLatestWith(this.hoosierState.select('activeSection'), this.hoosierState.select('PRICE_PREVIEWS_STATUS')),
        tap(([promoCode, activeSection, PRICE_PREVIEWS_STATUS]: [string, Section | null, OpStatus]) => {
          // this.alertMessageService.clearAll(AlertGroup.PROMO_CODE)
          /*
                    if (activeSection !== JoinSection.PRICE_PREVIEW) {
                      this.alertService.clearAll(AlertGroup.T_MOBILE)
                    }
            */
          if (promoCode && activeSection === JoinSection.PRICE_PREVIEW) {
            if (PRICE_PREVIEWS_STATUS === OpStatus.RUNNING) {
              this.alertMessageService.set(AlertMessage.PROMO_CODE_LOOKUP, AlertType.WARNING);
            }
            /*
                          if (PRICE_PREVIEWS_STATUS !== OpStatus.RUNNING) {
                          }
              */

            /**
             * refresh all tokens on the page when PRICE_PREVIEWS succeeds
             * save promoCode if pricePreviews recognizes the promoCode as valid
             */
            if (PRICE_PREVIEWS_STATUS === OpStatus.SUCCESS) {
              this.globalState.set('tokenRefresh', () => Date.now());
              const PRICE_PREVIEWS = this.hoosierState.get('PRICE_PREVIEWS');

              const promoCodeApplied = PRICE_PREVIEWS?.response.promoCode === promoCode;

              /*
                              const promoPrices = PRICE_PREVIEWS.response.promoNoEnrollPrices
                              const noPromoPrices = PRICE_PREVIEWS.response.noPromoNoEnrollPrices
                              for (const [index, promoPrice] of promoPrices.entries()) {
                                if (!promoPrice.error) {
                                  if (promoPrice.associateFee !== noPromoPrices[index].associateFee) {
                                    promoCodeApplied = true
                                  }
                                  if (promoPrice.primaryFee !== noPromoPrices[index].primaryFee) {
                                    promoCodeApplied = true
                                  }
                                  for (const [priceIndex, price] of promoPrice.prices.entries()) {
                                    if (price.price !== noPromoPrices[index].prices[priceIndex].price) {
                                      promoCodeApplied = true
                                    }
                                  }
                                }
                              }
                */

              this.globalState.set('userSession', (oldState) => {
                if (promoCodeApplied) {
                  this.alertMessageService.set(AlertMessage.PROMO_CODE_VALID, AlertType.SUCCESS);
                  oldState.userSession.promoCode = promoCode;
                }
                if (!promoCodeApplied && promoCode) {
                  this.alertMessageService.set(AlertMessage.PROMO_CODE_INVALID, AlertType.ERROR);
                  oldState.userSession.promoCode = '';
                }
                return oldState.userSession;
              });
            }
          }
        }),
      );
    }
    return of(null);
  }

  get couponCode$(): Observable<any> {
    return this.globalState.select('userSession', 'couponCode').pipe(
      combineLatestWith(this.hoosierState.select('activeSection'), this.hoosierState.select('PRICE_PREVIEWS_STATUS')),
      tap(([couponCode, activeSection, PRICE_PREVIEWS_STATUS]: [string | false, Section | null, OpStatus]) => {
        this.alertMessageService.clearAll(AlertGroup.T_MOBILE);

        if (couponCode) {
          if (PRICE_PREVIEWS_STATUS === OpStatus.RUNNING) {
            this.alertMessageService.set(AlertMessage.T_MOBILE_LOOKUP, AlertType.WARNING);
          }
          // if (PRICE_PREVIEWS_STATUS !== OpStatus.RUNNING) {
          // }
          /**
           * TODO: use T-Mobile response to determine AlertMessage
           */
          const PRICE_PREVIEWS = this.hoosierState.get('PRICE_PREVIEWS');
          if (PRICE_PREVIEWS && PRICE_PREVIEWS_STATUS === OpStatus.SUCCESS) {
            switch (PRICE_PREVIEWS.response.promoNoEnrollPrices[0].error?.responseCode) {
              case '202': // The coupon code is already in reserved state
                this.alertMessageService.set(AlertMessage.T_MOBILE_RESERVED, AlertType.ERROR);
                break;
              case '203': // The coupon code has already been redeemed.
                this.alertMessageService.set(AlertMessage.T_MOBILE_REDEEMED, AlertType.ERROR);
                break;
              case '211': // An internal server error has occurred
                this.alertMessageService.set(AlertMessage.T_MOBILE_INVALID, AlertType.ERROR);
                break;
              case undefined: // indicates success
                break;
              default:
              // this.alertMessageService.set(AlertMessage.T_MOBILE_FAILS, AlertType.ERROR)
            }
            if (!PRICE_PREVIEWS.response.promoNoEnrollPrices[0].error) {
              this.alertMessageService.set(AlertMessage.T_MOBILE_SUCCESS, AlertType.SUCCESS);
              this.form.get(['membershipPayload', 'membership'])?.patchValue({ autoRenew: true });
            }
            if (PRICE_PREVIEWS.response.promoNoEnrollPrices[0].error) {
              if (activeSection !== JoinSection.PRICE_PREVIEW) {
                /**
                 * remove membership level and purge couponCode
                 */
                this.globalState.set('userSession', (oldState) => {
                  oldState.userSession.couponCode = '';
                  return oldState.userSession;
                });
                this.form.get(['membershipPayload', 'membership', 'couponCode'])?.reset();
                // this.linkService.replaceUrlArgument("membershipLevel", "")
              }
            }
          }
        }
      }),
    );
  }

  pricePreviews_account$ = this.globalState.select('userSession', 'zipcode').pipe(
    combineLatestWith(this.hoosierState.select('activeFlow')),
    debounceTime(2000),
    tap(([zipcode, activeFlow]: [string, Flow | null]) => {
      if (activeFlow === Flow.ACCOUNT && zipcode) {
        this.pricePreviews(zipcode, '', '');
      }
    }),
  );

  get pricePreviews$(): Observable<any> {
    const couponCodeForm = this.form.get(['membershipPayload', 'membership', 'couponCode']);
    const promoCodeForm = this.form.get(['membershipPayload', 'membership', 'promoCode']);
    if (couponCodeForm && promoCodeForm) {
      return this.hoosierState.select('activeFlow').pipe(
        combineLatestWith(
          couponCodeForm.valueChanges,
          promoCodeForm.valueChanges,
          this.globalState.select('userSession', 'zipcode'),
        ),
        debounceTime(2000),
        tap(([activeFlow, couponCode, promoCode, zipcode]: [Flow | null, string, string, string]) => {
          if (zipcode) {
            switch (activeFlow) {
              case Flow.JOIN:
              case Flow.GIFT:
              case Flow.ACCOUNT:
                this.pricePreviews(zipcode, couponCode, promoCode);
                break;
              case Flow.QUICK_RENEW:
              case Flow.RENEW:
            }
          }
        }),
      );
    }
    return of(null);
  }

  retryOnError$ = this.hoosierState.select('PRICE_PREVIEWS_STATUS').pipe(
    takeWhile(() => this.numberOfRetries < 3),
    debounceTime(10000),
    tap((status) => {
      if (status === OpStatus.IS_ERROR) {
        this.alertMessageService.set(AlertMessage.ERROR_RETRY, AlertType.INFO);
        const zipcode = this.globalState.get('userSession', 'zipcode');
        const couponCode = this.form.get(['membershipPayload', 'membership', 'couponCode'])?.value;
        const promoCode = this.form.get(['membershipPayload', 'membership', 'promoCode'])?.value;
        this.pricePreviews(zipcode, couponCode, promoCode, true);
      }
    }),
  );

  PRICE_PREVIEWS_KEY$ = this.hoosierState.select('PRICE_PREVIEWS_KEY').pipe(
    combineLatestWith(this.hoosierState.select('sessionDoc', 'responses', 'membership', 'connectsuite')),
    tap(([PRICE_PREVIEWS_KEY, connectsuite]: [string | null, SessionDocResponse]) => {
      if (PRICE_PREVIEWS_KEY && connectsuite[PRICE_PREVIEWS_KEY]) {
        this.hoosierState.set('PRICE_PREVIEWS_KEY', () => null);

        const responseTime = Timestamp.now().toMillis() - parseInt(PRICE_PREVIEWS_KEY);

        if (this.globalState.get('adminUser')) {
          console.log(responseTime, 'milliseconds - PRICE_PREVIEWS');
        }
        /*
                    const zipcode = this.globalState.get("userSession", "zipcode")
                    const couponCode = this.form.get(["membershipPayload", "membership", "couponCode"]).value
                    const promoCode = this.form.get(["membershipPayload", "membership", "promoCode"]).value
          */

        const isError: boolean = connectsuite[PRICE_PREVIEWS_KEY].meta.isError;

        if (!isError) {
          this.alertMessageService.clearAll(AlertGroup.ERROR);
          this.hoosierState.set(
            'PRICE_PREVIEWS',
            () => connectsuite[PRICE_PREVIEWS_KEY] as PricePreviewsResponseObject,
          );
          this.hoosierState.set('PRICE_PREVIEWS_ERROR', () => null);
          this.hoosierState.set('PRICE_PREVIEWS_STATUS', () => OpStatus.SUCCESS);
        }
        if (isError) {
          this.hoosierState.set(
            'PRICE_PREVIEWS_ERROR',
            () => connectsuite[PRICE_PREVIEWS_KEY] as PricePreviewsResponseObject,
          );
          this.hoosierState.set('PRICE_PREVIEWS_STATUS', () => OpStatus.IS_ERROR);
          /*
                        if (this.retryCounter <= this.retryLimit) {
                          await new Promise(r => setTimeout(r, this.retryIncrement ** this.retryCounter * 1000)) // doubling when retryIncrement is 2
                          this.retryCounter++
                          this.pricePreviews(zipcode, couponCode, promoCode, true)
                        }
                        if (this.retryCounter > this.retryLimit) {
                          this.alertMessageService.set(AlertMessage.ERROR_CRITICAL, AlertType.ERROR)
                        }
            */
        }
      }
    }),
  );

  pricePreviews(zipcode: string, couponCode: string, promoCode: string, retry?: boolean): void {
    if (!this.hoosierState.get('EXECUTE_KEY')) {
      if (retry) {
        this.numberOfRetries++;
      }
      if (!retry) {
        this.numberOfRetries = 0;
      }
      const responseKey = Timestamp.now().toMillis().toString();
      this.hoosierState.set('PRICE_PREVIEWS_KEY', () => responseKey);
      this.hoosierState.set('PRICE_PREVIEWS_STATUS', () => OpStatus.RUNNING);

      const cacheResetTimestamp = 1672763711293; // Jan 3rd 11AM EST
      const cacheSettings: SaveCacheSettings = {
        cacheType: 'save',
        context: 'globalContext',
        maxAge: cacheResetTimestamp || Timestamp.now().toMillis() - 24 * 3600 * 1000, // get latest cache up to 1 days ago
      };
      const payload: MembershipConnectSuitePricePreviewsEventPayload = {
        cacheSettings: cacheSettings,
        method: MembershipConnectSuiteMethod.PRICE_PREVIEWS,
        couponCode: couponCode,
        postalCode: zipcode,
        promoCode: promoCode,
        responseKey: responseKey,
      };

      if (this.globalState.get('adminUser')) {
        // console.log(payload)
      }
      this.joinRenewService
        .sendToEventCoordinatorReceiver(EventName.MEMBERSHIP_QUERY, payload)
        .then((response) => {
          if (this.globalState.get('adminUser')) {
            console.log(
              EventName.MEMBERSHIP_QUERY +
                '---' +
                MembershipConnectSuiteMethod.PRICE_PREVIEWS +
                '---' +
                responseKey +
                '---' +
                response,
            );
          }
        })
        .catch((error) => {
          // console.log(error)
        });
    }
  }

  pricePreviewsFunction(
    pricePreviews: PricePreviewsResponse,
    level: string,
    rv: boolean,
    quantity: number,
  ): number | undefined {
    if (pricePreviews) {
      return (
        pricePreviews.promoNoEnrollPrices
          .find((price) => price.level.toLowerCase() === level.toLowerCase() && price.rv === rv)
          ?.prices?.find((price) => price.members === quantity)?.price ||
        pricePreviews.noPromoNoEnrollPrices
          .find((price) => price.level.toLowerCase() === level.toLowerCase() && price.rv === rv)
          ?.prices?.find((price) => price.members === quantity)?.price
      );
    }
    return;
  }
}
