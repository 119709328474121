<ng-container *ngIf="globalState$ | async as g">
  <div *nzModalTitle style="display: flex; gap: 10px; padding: 16px 24px; align-items: center">Page Settings</div>

  <div *ngIf="formGroup" [formGroup]="formGroup" style="padding: 16px 24px; display: flex; flex-wrap: wrap; gap: 10px">
    <nz-collapse [nzBordered]="true" style="width: 100%; user-select: none">
      <nz-collapse-panel nzHeader="Titles" [nzActive]="false" [nzShowArrow]="true">
        <div style="display: flex; flex-wrap: wrap; gap: 10px">
          H1 Page Title:
          <input type="text" nz-input formControlName="pageTitle" style="width: 100%" />
          Breadcrumb Title:
          <input type="text" nz-input formControlName="breadcrumbTitle" style="width: 100%" />
        </div>
      </nz-collapse-panel>
    </nz-collapse>

    <nz-collapse [nzBordered]="true" style="width: 100%; user-select: none">
      <nz-collapse-panel nzHeader="URL Path" [nzActive]="false" [nzShowArrow]="true">
        {{ g.location.origin + g.location.pathname }}
        <!--
              <input
                nz-input
                formControlName="pathName"
                style="width: 100%"
              >
        -->
      </nz-collapse-panel>
    </nz-collapse>

    <nz-collapse [nzBordered]="true" style="width: 100%; user-select: none">
      <nz-collapse-panel [nzHeader]="pageTypeHeader" [nzActive]="false" [nzShowArrow]="true">
        <nz-radio-group formControlName="pageType" style="display: flex; flex-wrap: wrap; gap: 10px">
          <label nz-radio *ngFor="let page of pages" [nzValue]="page.type">{{ page.label }}</label>
        </nz-radio-group>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
</ng-container>
