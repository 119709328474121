<div
  #Header
  style="
    padding: 16px;
    display: grid;
    place-content: center;
    font-weight: 600;
    font-size: 18px;
    border-bottom: solid 1px #aaaaaa;
    user-select: none;
  "
>
  Member Lookup
</div>

<div style="display: flex; gap: 10px; flex-direction: column; padding: 20px">
  <div style="display: flex; gap: 10px">
    <input
      nz-input
      [(ngModel)]="memberNumber"
      [type]=""
      [name]=""
      [placeholder]=""
      style="font-family: monospace"
      (ngModelChange)="inputChanges($event)"
    />
    <button nz-button nzType="primary" [disabled]="memberNumber?.length !== 16" (click)="memberLookup(memberNumber)">
      Do Lookup
    </button>
  </div>

  <div *ngIf="memberLookupDuration">Response Time: {{ memberLookupDuration }} seconds</div>

  <div *ngIf="memberLookupString">
    Response:
    <nz-code-editor
      class="editor"
      style="height: 60vh"
      nzEdit
      [ngModel]="memberLookupString"
      [nzEditorOption]="{ language: 'json' }"
    ></nz-code-editor>
  </div>
</div>
