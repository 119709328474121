import { Component, Inject, OnInit } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { RxState } from '@rx-angular/state';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../../services/state';
import { ScreenSize } from '../../../../../services/style';
import {
  AccountMembershipView,
  AccountSection,
  AccountViewLabel,
  CheckoutStep,
  Flow,
  HOOSIER_RX_STATE,
  HoosierService,
  HoosierState,
  Section,
} from '../../hoosier.service';
import { ExecuteService } from '../../events/execute';
import { combineLatestWith, tap } from 'rxjs';
import { RxEffects } from '@rx-angular/state/effects';
import { LinkService } from '../../../../../services/link';
import { ValidateAssociatesService } from '../../events/validate-associates';
import { ValidateLevelService } from '../../events/validate-level';
import { PricePreviewsService } from '../../events/price-previews';
import { FormGroup } from '@angular/forms';
import { ValidateRenewService } from '../../events/validate-renew';
import { ValidateChangeAutoRenewService } from '../../events/validate-auto-renew';
import { NzModalService } from 'ng-zorro-antd/modal';
import {
  ConnectSuiteMembershipCode,
  MembershipPayloadAssociate,
} from '@aaa/interface-joinRenew-membership-membershipConnectSuite';

export type PhoneType = 'cell' | 'home' | 'business';

export type AccountPhone = { [key in PhoneType]: string };

export type AccountAddress = {
  street1: string;
  street2: string;
  city: string;
  state: string;
  zip: string;
};

export interface AccountDetails {
  address: AccountAddress;
  associateCount: number | null;
  associates: MembershipPayloadAssociate[];
  // associatesCount: number
  autoRenew: boolean | null;
  balance: number | null;
  cardNumber: string;
  code: ConnectSuiteMembershipCode | undefined | null;
  email: string;
  expired: boolean | null;
  expiresDateFormatted: string;
  expiresDateString: string;
  expiresTimestamp: number | null;
  firstName: string;
  lastName: string;
  memberNumber: string;
  middleInitial: string;
  nameSuffix: string;
  phone: AccountPhone;
  status: AccountStatus;
  statusText: string;
}

export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  GUEST = 'GUEST',
  PENDING = 'PENDING',
  UNKNOWN = 'UNKNOWN',
}

export interface AccountSectionData {
  icon: string;
  title: string;
  description: string;
  enum: AccountSection;
}

@Component({
  selector: 'ava-hoosier-account',
  templateUrl: './account.html',
})
export class AccountComponent implements OnInit {
  globalState$ = this.globalState.select();
  hoosierState$ = this.hoosierState.select();
  ScreenSize = ScreenSize;
  AccountSection = AccountSection;
  AccountStatus = AccountStatus;
  form: FormGroup;
  displayLeftColumn: 'block' | 'none' = 'none';
  displayRightColumn: 'block' | 'none' = 'none';

  links = [
    {
      path: '/enewsletters',
      text: 'Manage Email Preferences',
    },
    {
      path: '/account/logout',
      text: 'Log Out of AAA.com',
    },
  ];
  sections: AccountSectionData[] = [
    {
      icon: 'icons:account',
      title: 'Account Information',
      description: 'Change Email, Password',
      enum: AccountSection.INFORMATION,
    },
    {
      icon: 'icons:membership',
      title: 'Membership',
      description: 'Change Membership, Add Members',
      enum: AccountSection.MEMBERSHIP,
    },
    {
      icon: 'icons:insurance',
      title: 'Insurance',
      description: 'Pay, Manage Insurance',
      enum: AccountSection.INSURANCE,
    },
    {
      icon: 'icons:travel',
      title: 'Travel',
      description: 'My Reservation, New Plans',
      enum: AccountSection.TRAVEL,
    },
    {
      icon: 'icons:financial',
      title: 'Financial',
      description: 'Credit Card, Identity Theft Protection',
      enum: AccountSection.FINANCIAL,
    },
  ];

  // accountDetails: AccountDetails
  accountViewLabel: AccountViewLabel | undefined;

  constructor(
    private executeService: ExecuteService, // required TODO: but maybe load it downstream
    private pricePreviewsService: PricePreviewsService, // required TODO: but maybe load it downstream
    private validateAssociatesService: ValidateAssociatesService, // required TODO: but maybe load it downstream
    private validateAutoRenewService: ValidateChangeAutoRenewService, // required TODO: but maybe load it downstream
    private validateLevelService: ValidateLevelService, // required TODO: but maybe load it downstream
    private validateRenewService: ValidateRenewService, // required TODO: but maybe load it downstream
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
    private linkService: LinkService,
    private nzModalService: NzModalService,
  ) {
    rxEffects.register(this.displayColumns$);
    this.form = hoosierService.form;
  }

  displayColumns$ = this.globalState.select('pageWidth').pipe(
    combineLatestWith(this.globalState.select('screenSize'), this.hoosierState.select('activeSection')),
    tap(([pageWidth, screenSize, activeSection]: [number, ScreenSize, Section | null]) => {
      switch (screenSize) {
        case ScreenSize.DESKTOP:
          this.displayLeftColumn = 'block';
          this.displayRightColumn = 'block';
          break;
        case ScreenSize.MOBILE:
        case ScreenSize.TABLET:
          switch (activeSection) {
            case AccountSection.HOME:
              this.displayLeftColumn = 'block';
              this.displayRightColumn = 'none';
              break;
            default:
              this.displayLeftColumn = 'none';
              this.displayRightColumn = 'block';
          }
      }
    }),
  );

  ngOnInit(): void {
    this.accountViewLabel = this.hoosierService.accountViewLabel;
  }

  section(
    section: AccountSection,
  ): { enum: AccountSection; icon: string; title: string; description: string } | undefined {
    return this.sections.find((s) => s.enum === section);
  }

  /**
   * Navigation Bars for transitioning backwards
   */
  setActiveSection(activeSection: AccountSection, activeView: AccountMembershipView): void {
    let view = AccountMembershipView.HOME;
    let section = activeSection;
    switch (this.hoosierState.get('activeCheckoutStep')) {
      case CheckoutStep.CONFIRMATION:
      case CheckoutStep.EXECUTE:
      case CheckoutStep.PAYMENT:
      case CheckoutStep.VALIDATE:
        this.hoosierState.set('activeCheckoutStep', () => null);
        break;
      case null:
        switch (activeView) {
          case AccountMembershipView.HOME:
            section = AccountSection.HOME;
            break;
          case AccountMembershipView.ASSOCIATES_ADD:
          case AccountMembershipView.ASSOCIATES_REMOVE:
            if (this.hoosierState.get('accountDetails', 'associateCount')) {
              view = AccountMembershipView.ASSOCIATES;
            }
            break;
          case AccountMembershipView.AUTO_RENEW_CARD:
            view = AccountMembershipView.AUTO_RENEW;
            break;
          case AccountMembershipView.AUTO_RENEW_REMOVE:
            view = AccountMembershipView.AUTO_RENEW_REMOVE;
            break;
          default:
        }
        this.linkService.replaceUrlArgument(Flow.ACCOUNT, Flow.ACCOUNT + '/' + section + '/' + view);
        break;
    }
  }

  keyDownSetActiveSection(event: KeyboardEvent, section: AccountSection, activeView: AccountMembershipView): void {
    if (event.code === 'Enter' || event.code === 'Space') {
      this.setActiveSection(section, activeView);
      // this.linkService.replaceUrlArgument(Flow.ACCOUNT, Flow.ACCOUNT + "/" + section + "/" + DefaultView.HOME)
    }
  }

  closeModal(event: Event): void {
    event.stopPropagation();
    this.nzModalService.closeAll();
  }
}
