import { Injectable } from '@angular/core';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { PageSettingsFormComponent } from './form';

export enum PageType {
  DEFAULT = 'DEFAULT',
  OFFICE = 'OFFICE',
  TRAVEL = 'TRAVEL',
  MAGAZINE = 'MAGAZINE',
  JOIN = 'JOIN',
  QUICK_RENEW = 'QUICK_RENEW',
  GIFT = 'GIFT',
  MOBILE_APP_RENEW = 'MOBILE_APP_RENEW',
}

export interface Page {
  label: string;
  type: PageType;
}

@Injectable({
  providedIn: 'root',
})
export class PageSettingsService {
  private modalRef: NzModalRef | undefined;

  constructor(private modalService: NzModalService) {}

  get pages(): Page[] {
    return [
      {
        label: 'Default',
        type: PageType.DEFAULT,
      },
      {
        label: 'Office',
        type: PageType.OFFICE,
      },
      {
        label: 'Travel',
        type: PageType.TRAVEL,
      },
      {
        label: 'Magazine',
        type: PageType.MAGAZINE,
      },
      {
        label: 'Join',
        type: PageType.JOIN,
      },
      {
        label: 'Quick Renew',
        type: PageType.QUICK_RENEW,
      },
      {
        label: 'Gift',
        type: PageType.GIFT,
      },
      {
        label: 'Mobile App Renew',
        type: PageType.MOBILE_APP_RENEW,
      },
    ];
  }

  showModal(): void {
    this.modalRef = this.modalService.create({
      nzContent: PageSettingsFormComponent,
      // nzBodyStyle: { "padding": "20px" },
      nzFooter: null,
      // nzMaskClosable: false,
      // nzStyle: { position: "absolute", top: "28px", right: 0 },
      // nzStyle: { "width": "80vw" },
      // nzComponentParams: {
      //   stateId: stateId
      // }
    });
  }
}
