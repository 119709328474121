import { Inject, Injectable } from '@angular/core';
import { GLOBAL_RX_STATE, GlobalState } from './state';
import { RxState } from '@rx-angular/state';
import { FormService } from './form';
import { StatusService } from '../options/status/service';
import { BlockService } from '../block/services/block';
import { Template, TemplateService } from '../options/template/service';
import { BlockBase, BlockOption } from '../block/services/block';

export interface Block extends BlockBase {
  breadcrumbTitle: string;
  containerType: 'page';
  pageType: string;
  pageTitle: string;
  template: Template;
}

@Injectable({
  providedIn: 'root',
})
export class MultiblockService {
  blockType: BlockOption = 'container';
  containerType: Block['containerType'] = 'page';

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private formService: FormService,
    private statusService: StatusService,
    private multiblockService: BlockService,
    private templateService: TemplateService,
  ) {}

  get newBlock(): Block {
    return {
      blockShared: false,
      blockType: this.blockType,
      blockTemplate: false,
      columns: [this.multiblockService.newColumn],
      rows: {},
      containerType: this.containerType,
      breadcrumbTitle: '',
      id: '',
      pageTitle: '',
      pageType: '',
      pathName: this.globalState.get('location', 'pathname'),
      pathNames: [],
      status: this.statusService.newItem,
      template: this.templateService.newItem,
    };
  }

  pageClicked(): void {
    this.globalState.set({
      createPanelVisible: false,
      editorVisible: false,
    });
  }
}
