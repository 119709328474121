import { Component, Inject } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/firestore';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../../services/state';
import { HOOSIER_RX_STATE, HoosierState, SessionDocResponse } from '../../hoosier.service';
import { MemberLookupResponse, MemberLookupResponseObject, MemberLookupService } from '../../events/member-lookup';
import { combineLatestWith, tap } from 'rxjs';
import { OpStatus } from '../../../join-renew.service';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'ava-member-lookup',
  templateUrl: './view.html',
  providers: [RxState, RxEffects],
})
export class MemberLookupComponent {
  memberNumber: string | undefined;
  memberLookupDuration: number | undefined;
  memberLookupString: string | undefined;

  constructor(
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private memberLookupService: MemberLookupService,
    private rxEffects: RxEffects,
  ) {
    rxEffects.register(this.memberLookup$);
  }

  /**
   * TODO: consider moving into member-lookup service
   */
  memberLookup$ = this.hoosierState.select('MEMBER_LOOKUP_ADMIN_KEY').pipe(
    combineLatestWith(this.hoosierState.select('sessionDoc', 'responses', 'membership', 'connectsuite')),
    tap(([MEMBER_LOOKUP_ADMIN_KEY, connectsuite]: [string | null, SessionDocResponse]) => {
      if (MEMBER_LOOKUP_ADMIN_KEY && connectsuite[MEMBER_LOOKUP_ADMIN_KEY]) {
        this.hoosierState.set('MEMBER_LOOKUP_ADMIN_KEY', () => null);
        this.memberLookupDuration = (Timestamp.now().toMillis() - parseInt(MEMBER_LOOKUP_ADMIN_KEY)) / 1000;

        const MEMBER_LOOKUP_ADMIN = connectsuite[MEMBER_LOOKUP_ADMIN_KEY] as MemberLookupResponseObject;

        if (!MEMBER_LOOKUP_ADMIN.meta.isError) {
          this.hoosierState.set('MEMBER_LOOKUP_ADMIN', () => MEMBER_LOOKUP_ADMIN);
          this.hoosierState.set('MEMBER_LOOKUP_ADMIN_ERROR', () => null);
          this.hoosierState.set('MEMBER_LOOKUP_ADMIN_STATUS', () => OpStatus.SUCCESS);
          const memberLookupResponse: MemberLookupResponse = MEMBER_LOOKUP_ADMIN.response;
          const memberLookupData = {
            attributes: memberLookupResponse?.memberInfo?.attributes,
            membership: memberLookupResponse?.memberInfo?.membership,
          };
          this.memberLookupString = JSON.stringify(memberLookupData, null, 4);
        }
        if (MEMBER_LOOKUP_ADMIN.meta.isError) {
          this.hoosierState.set('MEMBER_LOOKUP_ADMIN', () => null);
          this.hoosierState.set('MEMBER_LOOKUP_ADMIN_ERROR', () => MEMBER_LOOKUP_ADMIN);
          this.hoosierState.set('MEMBER_LOOKUP_ADMIN_STATUS', () => OpStatus.IS_ERROR);
          this.memberLookupString = JSON.stringify(MEMBER_LOOKUP_ADMIN, null, 4);
        }
      }
    }),
  );

  memberLookup(memberNumber: string | undefined): void {
    if (memberNumber) {
      this.memberLookupService.memberLookup(memberNumber, 'MEMBER_LOOKUP_ADMIN_KEY');
    }
  }

  inputChanges(event: string): void {
    if (event.length > 16) {
      event = event.replace(/\s/g, '');
    }
    if (event.length === 16) {
      this.memberLookup(event);
    }
  }
}
